.Sidebar{
  position: fixed;
  top: 0;
  left: 0;
  width: 15%;
  height: 100%;

  padding: 50px 0;
  background: rgb(67, 85, 160);
  color: #fff;

  text-align: center;
}

.sidebarImage {
  width: 60%;
  margin-bottom: 30px;
  margin-top: 10px;
}

.sidebarButton {
  width: 100%;
  background: #6f87f3;
  height: 5%;
  color: #fff;
  font-weight: 700;
  cursor: pointer;

  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid rgb(67, 85, 160);
}

.sidebarButton.active {
  background: #889bf0;
}

.sidebarButton:hover {
  background: #889bf0;
  transition: background 0.1s;
}